export default [
  {
    path: '/store-queue',
    meta: {
      pageTitle: 'Dashboard',
      service: 'store-queue',
      resource: 'Store-queue-dashboard',
      action: 'read',
    },
    redirect: { name: 'store-queue-dashboard' },
  },
  {
    path: '/store-queue/dashboard',
    name: 'store-queue-dashboard',
    component: () => import('@/views/store-queue/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      service: 'store-queue',
      resource: 'Store-queue-dashboard',
      action: 'read',
    },
  },
  {
    path: '/store-queue/monitor',
    name: 'store-queue-monitor',
    component: () => import('@/views/store-queue/queueMonitor/QueueMonitor.vue'),
    meta: {
      pageTitle: 'Queue Monitor',
      service: 'store-queue',
      resource: 'Store-queue-monitor',
      action: 'read',
    },
  },
  {
    path: '/store-queue/services',
    name: 'store-queue-services',
    component: () => import('@/views/store-queue/services/Services.vue'),
    meta: {
      pageTitle: 'Queue Services',
      service: 'store-queue',
      resource: 'Store-queue-services',
      action: 'read',
    },
  },
  {
    path: '/store-queue/services/view/:id',
    name: 'store-queue-services-view',
    component: () => import('@/views/store-queue/services/ViewService.vue'),
    meta: {
      pageTitle: 'Queue Services View',
      service: 'store-queue',
      resource: 'Store-queue-services-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Queue Services',
          to: '/store-queue/services',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'store-queue-services',
    },
  },
  {
    path: '/store-queue/services/period/:id',
    name: 'store-queue-services-period',
    component: () => import('@/views/store-queue/services/pages/period/Period.vue'),
    meta: {
      pageTitle: 'Queue Services Period',
      service: 'store-queue',
      resource: 'Store-queue-services-period',
      action: 'read',
      breadcrumb: [
        {
          text: 'Queue Services',
          to: '/store-queue/services',
        },
        {
          text: 'Period',
          active: true,
        },
      ],
      navActiveLink: 'store-queue-services',
    },
  },
  {
    path: '/store-queue/packagepurchase',
    name: 'store-queue-package-purchase',
    component: () => import('@/views/store-queue/packagePurchase/PackagePurchase.vue'),
    meta: {
      pageTitle: 'Package Purchase',
      service: 'store-queue',
      resource: 'Store-queue-package-purchase',
      action: 'read',
    },
  },
  {
    path: '/store-queue/promotion',
    name: 'store-queue-promotion',
    component: () => import('@/views/store-queue/promotion/Promotion.vue'),
    meta: {
      pageTitle: 'Promotion',
      service: 'store-queue',
      resource: 'Store-queue-promotion',
      action: 'read',
    },
  },
  {
    path: '/store-queue/coupon',
    name: 'store-queue-coupon',
    component: () => import('@/views/store-queue/coupon/Coupon.vue'),
    meta: {
      pageTitle: 'Coupon',
      service: 'store-queue',
      resource: 'Store-queue-coupon',
      action: 'read',
    },
  },
  {
    path: '/store-queue/question',
    name: 'store-queue-question',
    component: () => import('@/views/store-queue/question/Question.vue'),
    meta: {
      pageTitle: 'Question',
      service: 'store-queue',
      resource: 'Store-queue-question',
      action: 'read',
    },
  },
  {
    path: '/store-queue/question/:id',
    name: 'store-queue-question-view',
    component: () => import('@/views/store-queue/question/QuestionDetailView.vue'),
    meta: {
      pageTitle: 'Question View',
      service: 'store-queue',
      resource: 'Store-queue-question-view',
      action: 'read',
      breadcrumb: [
        {
          text: 'Question Details',
          to: '/store-queue/question',
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'store-queue-question',
    },
  },
  {
    path: '/store-queue/about',
    name: 'store-queue-about',
    component: () => import('@/views/store-queue/about/About.vue'),
    meta: {
      pageTitle: 'About',
      service: 'store-queue',
      resource: 'Store-queue-about',
      action: 'read',
    },
  },
]
