export default [
  {
    path: '/store-grocery',
    meta: {
      pageTitle: 'Dashboard',
      service: 'store-grocery',
      resource: 'Store-grocery-dashboard',
      action: 'read',
    },
    redirect: { name: 'store-grocery-dashboard' },
  },
  {
    path: '/store-grocery/dashboard',
    name: 'store-grocery-dashboard',
    component: () => import('@/views/store-grocery/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      service: 'store-grocery',
      resource: 'Store-grocery-dashboard',
      action: 'read',
    },
  },
]
