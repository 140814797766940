import Vue from 'vue'

// axios
import axios from 'axios'

const domain = process.env.NODE_ENV === 'production' ? `${process.env.VUE_APP_API_SERVER}` : 'http://localhost:33208'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  baseURL: domain,
  headers: {
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Methods': '*',
    // 'Access-Control-Allow-Headers': '*',
    // 'Access-Control-Allow-Credentials': true,
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-type': 'application/json',
  },
  responseType: 'json',
})

Vue.prototype.$http = axiosIns

export default axiosIns
