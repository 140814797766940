export default [
  {
    route: 'driver-call-dashboard',
    title: 'driver-call-dashboard',
    slug: 'dashboard',
    icon: 'HomeIcon',
    i18n: 'Dashboard',
    resource: 'Driver-call-dashboard',
    action: 'read',
  },
]
