export default [
  {
    path: '/services',
    meta: {
      pageTitle: 'Dashboard',
      service: 'services',
      resource: 'Services-dashboard',
      action: 'read',
    },
    redirect: { name: 'services-dashboard' },
  },
  {
    path: '/services/dashboard',
    name: 'services-dashboard',
    component: () => import('@/views/services/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      service: 'services',
      resource: 'Services-dashboard',
      action: 'read',
    },
  },
]
