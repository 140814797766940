export default [
  {
    path: '/store-food',
    meta: {
      pageTitle: 'Dashboard',
      service: 'store-food',
      resource: 'Store-food-dashboard',
      action: 'read',
    },
    redirect: { name: 'store-food-dashboard' },
  },
  {
    path: '/store-food/dashboard',
    name: 'store-food-dashboard',
    component: () => import('@/views/store-food/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      service: 'store-food',
      resource: 'Store-food-dashboard',
      action: 'read',
    },
  },
]
