export default [
  {
    path: '/driver-call',
    meta: {
      pageTitle: 'Dashboard',
      service: 'driver-grocery',
      resource: 'Driver-grocery-dashboard',
      action: 'read',
    },
    redirect: { name: 'driver-call-dashboard' },
  },
  {
    path: '/driver-call/dashboard',
    name: 'Driver-call-dashboard',
    component: () => import('@/views/driver-call/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      service: 'driver-call',
      resource: 'Driver-call-dashboard',
      action: 'read',
    },
  },
]
