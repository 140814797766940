const url = process.env.NODE_ENV === 'production' ? `${process.env.VUE_APP_WS_SERVER}` : 'ws://localhost:3315'
export default {
  namespaced: true,
  state: {
    wsClient: {},
  },
  getters: {
  },
  mutations: {
    initState_websocket(state, item) {
      state.wsClient[item.page] = item.wsConnect
    },
    sendMessage(state, item) {
      if (state.wsClient[item.page] !== null) {
        if (state.wsClient[item.page].readyState === 1) {
          state.wsClient[item.page].send(JSON.stringify(item.data))
        }
      }
    },
    disconnect_websocket(state, page) {
      if (state.wsClient[page] !== null) {
        state.wsClient[page].close()
        state.wsClient[page] = null
        delete state.wsClient[page]
      }
    },
    reconnect_websocket(state, page) {
      state.wsClient[page].close()
      state.wsClient[page] = null
      delete state.wsClient[page]
    },
  },
  actions: {
    connect({ commit }, item) {
      const accessToken = localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null
      const wsConnect = new WebSocket(url + item.pathname, accessToken)
      const obj = {
        wsConnect,
        page: item.page,
      }
      commit('initState_websocket', obj)
    },
    sendMessage({ commit }, item) {
      commit('sendMessage', item)
    },
    disconnect({ commit }, page) {
      commit('disconnect_websocket', page)
    },
  },
}
