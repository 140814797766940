/* eslint-disable no-use-before-define */
import storeGrocery from '../../router/store-grocery'
import storeFood from '../../router/store-food'
import storeQueue from '../../router/store-queue'
import driverDelivery from '../../router/driver-delivery'
import driverCall from '../../router/driver-call'
import services from '../../router/services'

import storeGroceryNav from '../../navigation/store-grocery'
import storeFoodNav from '../../navigation/store-food'
import storeQueueNav from '../../navigation/store-queue'
import driverDeliveryNav from '../../navigation/driver-delivery'
import driverCallNav from '../../navigation/driver-call'
import servicesNav from '../../navigation/services'

export const storeGroceryAbility = () => abilityLoop(storeGrocery, storeGroceryNav)
export const storeFoodAbility = () => abilityLoop(storeFood, storeFoodNav)
export const storeQueueAbility = () => abilityLoop(storeQueue, storeQueueNav)
export const driverDeliveryAbility = () => abilityLoop(driverDelivery, driverDeliveryNav)
export const driverCallAbility = () => abilityLoop(driverCall, driverCallNav)
export const servicesAbility = () => abilityLoop(services, servicesNav)

export const initialAbility = [
  // {
  //   action: 'read', // 'create' | 'read' | 'update' | 'delete';
  //   subject: 'Franchise-company',
  // },
]

function abilityLoop(data, dataNav) {
  const resource = []
  const array = []
  for (let index = 0; index < data.length; index += 1) {
    if (data[index].meta.resource !== 'Auth') {
      if (!resource.includes(data[index].meta.resource)) {
        resource.push(data[index].meta.resource)
        for (let indexArray = 0; indexArray < 4; indexArray += 1) {
          // eslint-disable-next-line no-nested-ternary
          array.push({ subject: data[index].meta.resource, action: indexArray === 0 ? 'read' : indexArray === 1 ? 'create' : indexArray === 2 ? 'update' : indexArray === 3 ? 'delete' : 'delete' })
        }
      }
    }
  }
  for (let index = 0; index < dataNav.length; index += 1) {
    if (dataNav[index].resource !== 'Auth') {
      if (!resource.includes(dataNav[index].resource)) {
        if (Array.isArray(dataNav[index].children)) {
          for (let indexResource = 0; indexResource < dataNav[index].children.length; indexResource += 1) {
            resource.push(dataNav[index].children[indexResource].resource)
          }
        }
        resource.push(dataNav[index].resource)
        for (let indexArray = 0; indexArray < 4; indexArray += 1) {
          // eslint-disable-next-line no-nested-ternary
          array.push({ subject: dataNav[index].resource, action: indexArray === 0 ? 'read' : indexArray === 1 ? 'create' : indexArray === 2 ? 'update' : indexArray === 3 ? 'delete' : 'delete' })
        }
        if (Array.isArray(dataNav[index].children)) {
          for (let indexResource = 0; indexResource < dataNav[index].children.length; indexResource += 1) {
            for (let indexArray = 0; indexArray < 4; indexArray += 1) {
              // eslint-disable-next-line no-nested-ternary
              array.push({ subject: dataNav[index].children[indexResource].resource, action: indexArray === 0 ? 'read' : indexArray === 1 ? 'create' : indexArray === 2 ? 'update' : indexArray === 3 ? 'delete' : 'delete' })
            }
          }
        }
      }
    }
  }
  return array
}

export const _ = undefined
