export default [
  {
    path: '/driver-delivery',
    meta: {
      pageTitle: 'Dashboard',
      service: 'driver-delivery',
      resource: 'Driver-delivery-dashboard',
      action: 'read',
    },
    redirect: { name: 'driver-delivery-dashboard' },
  },
  {
    path: '/driver-delivery/dashboard',
    name: 'driver-delivery-dashboard',
    component: () => import('@/views/driver-delivery/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      service: 'driver-delivery',
      resource: 'Driver-delivery-dashboard',
      action: 'read',
    },
  },
]
