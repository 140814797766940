export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      pageTitle: 'Page Not Found',
      layout: 'full',
      resource: 'Auth',
      service: 'Auth',
      action: 'read',
      errorPage: true,
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      pageTitle: 'Login',
      layout: 'full',
      resource: 'Auth',
      service: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      service: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      pageTitle: 'Register',
      layout: 'full',
      resource: 'Auth',
      service: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/select-user',
    name: 'select-user',
    component: () => import('@/views/pages/authentication/SelectUser.vue'),
    meta: {
      pageTitle: 'Select User',
      layout: 'full',
      resource: 'Auth',
      service: 'Auth',
      redirectIfLoggedIn: true,
      selectUser: true,
    },
  },
  {
    path: '/select-profile',
    name: 'select-profile',
    component: () => import('@/views/pages/authentication/SelectProfile.vue'),
    meta: {
      pageTitle: 'Select Profile',
      layout: 'full',
      service: 'Auth',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/select-profile/:id',
    name: 'select-profile-view',
    component: () => import('@/views/pages/authentication/components/AddProfile.vue'),
    meta: {
      pageTitle: 'Select Profile View',
      layout: 'full',
      service: 'Auth',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      service: 'Auth',
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
]
