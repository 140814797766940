export default [
  {
    route: 'services-dashboard',
    title: 'services-dashboard',
    slug: 'dashboard',
    icon: 'HomeIcon',
    i18n: 'Dashboard',
    resource: 'Services-dashboard',
    action: 'read',
  },
]
