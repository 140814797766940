import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
// eslint-disable-next-line no-unused-vars
import { canNavigate } from '@/libs/acl/routeProtection'
// eslint-disable-next-line no-unused-vars
import {
  getUserData, getAbility, getMenuService, isUserLoggedIn,
} from '@/auth/utils'

import storeGroceryRoute from './store-grocery'
import storeFoodRoute from './store-food'
import storeQueueRoute from './store-queue'
import driverDeliveryRoute from './driver-delivery'
import driverCallRoute from './driver-call'
import servicesRoute from './services'

import authRoute from './auth'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '',
      redirect: { name: 'auth-login' },
    },
    ...storeGroceryRoute,
    ...storeFoodRoute,
    ...storeQueueRoute,
    ...driverDeliveryRoute,
    ...driverCallRoute,
    ...servicesRoute,
    ...authRoute,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  // eslint-disable-next-line no-unused-vars
  const isLoggedIn = isUserLoggedIn()
  const userData = getUserData()
  // eslint-disable-next-line no-unused-vars
  const ability = getAbility()
  const menuService = getMenuService()

  // console.log(userData)
  // if (!canNavigate(to)) {
  //   if (to.meta.service !== 'Auth') {
  //     if (to.meta.service !== 'root') {
  //       // Redirect to login if not logged in
  //       if (to.meta.errorPage !== true) {
  //         // If logged in => not authorized
  //         return next({ name: 'error-404' })
  //       }
  //     }
  //   }
  // }
  console.log(!canNavigate(to))
  // console.log(ability)
  // if (!canNavigate(to) === false) {
  //   if (userData != null) {
  //     if (userData.userType === 'root') {
  //       return next({ name: 'root-dashboard' })
  //     } if (userData.userType === 'franchise') {
  //       const franchiseData = localStorage.getItem('franchiseData') ? localStorage.getItem('franchiseData') : null
  //       if (franchiseData != null) {
  //         return next({ name: 'franchise-dashboard' })
  //       }
  //       return next({ name: 'select-franchise' })
  //     } if (userData.userType === 'company') {
  //       const companyData = localStorage.getItem('companyData') ? localStorage.getItem('companyData') : null
  //       if (companyData != null) {
  //         return next({ name: 'company-dashboard' })
  //       }
  //       return next({ name: 'select-company' })
  //     }
  //   }
  // }
  // Redirect to login if not logged in
  // if (isLoggedIn === null) return next({ name: 'auth-login' })
  // // Redirect if logged in

  if (to.meta.redirectIfLoggedIn === true) {
    // console.log('redirectIfLoggedIn', to.meta.redirectIfLoggedIn)
    // console.log('isLoggedIn', isLoggedIn)
    if (to.meta.selectUser) {
      console.log('selectUser')
    } else if (userData !== null) {
      if (menuService === 'store-grocery') {
        return next({ name: 'store-grocery-dashboard' })
      } if (menuService === 'store-food') {
        return next({ name: 'store-food-dashboard' })
      } if (menuService === 'store-queue') {
        return next({ name: 'store-queue-dashboard' })
      } if (menuService === 'driver-delivery') {
        return next({ name: 'driver-delivery-dashboard' })
      } if (menuService === 'driver-call') {
        return next({ name: 'driver-call-dashboard' })
      } if (menuService === 'services') {
        return next({ name: 'services-dashboard' })
      }
      return next({ name: 'select-profile' })
    }
  } else if (isLoggedIn === null) {
    if (to.meta.errorPage !== true) {
      return next({ name: 'auth-login' })
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
