export default [
  {
    route: 'store-queue-dashboard',
    title: 'store-queue-dashboard',
    slug: 'dashboard',
    icon: 'HomeIcon',
    i18n: 'Dashboard',
    resource: 'Store-queue-dashboard',
    action: 'read',
  },
  {
    route: 'store-queue-monitor',
    title: 'store-queue-monitor',
    slug: 'monitor',
    icon: 'MonitorIcon',
    i18n: 'Queue Monitor',
    resource: 'Store-queue-monitor',
    action: 'read',
  },
  {
    route: 'store-queue-services',
    title: 'store-queue-services',
    slug: 'services',
    icon: 'BoxIcon',
    i18n: 'Services',
    resource: 'Store-queue-services',
    action: 'read',
  },
  {
    route: 'store-queue-package-purchase',
    title: 'store-queue-package-purchase',
    slug: 'package',
    icon: 'GiftIcon',
    i18n: 'Package Purchase',
    resource: 'Store-queue-package-purchase',
    action: 'read',
  },
  {
    route: 'store-queue-promotion',
    title: 'store-queue-promotion',
    slug: 'promotion',
    icon: 'GiftIcon',
    i18n: 'Promotion',
    resource: 'Store-queue-promotion',
    action: 'read',
  },
  {
    route: 'store-queue-coupon',
    title: 'store-queue-coupon',
    slug: 'coupon',
    icon: 'GiftIcon',
    i18n: 'Coupon',
    resource: 'Store-queue-coupon',
    action: 'read',
  },
  {
    route: 'store-queue-question',
    title: 'store-queue-question',
    slug: 'question',
    icon: 'GiftIcon',
    i18n: 'Question',
    resource: 'Store-queue-question',
    action: 'read',
  },
  {
    route: 'store-queue-about',
    title: 'store-queue-about',
    slug: 'about',
    icon: 'GiftIcon',
    i18n: 'About',
    resource: 'Store-queue-about',
    action: 'read',
  },
]
