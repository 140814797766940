import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import notifications from './notification'
import verticalMenu from './vertical-menu'
// eslint-disable-next-line import/extensions
import websockets from './websockets'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    notifications,
    verticalMenu,
    websockets,
  },
  strict: process.env.DEV,
})
